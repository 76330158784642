var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "card-content-types" } },
    [
      _c("h5", { staticClass: "mt-3" }, [_vm._v(" Content Types ")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("h6", { staticClass: "my-2 text-muted" }, [_vm._v(" Body ")]),
              _c(
                "b-card",
                { staticClass: "mb-4" },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " This is some text within a card body. Jelly lemon drops tiramisu chocolate cake cotton candy soufflé oat cake sweet roll. Sugar plum marzipan dragée topping cheesecake chocolate bar. Danish muffin icing donut. "
                    )
                  ])
                ],
                1
              ),
              _c("h6", { staticClass: "my-2 text-muted" }, [
                _vm._v(" Titles, Text, and Links ")
              ]),
              _c(
                "b-card",
                {
                  staticClass: "mb-4",
                  attrs: { title: "Card title", "sub-title": "Card subtitle" }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " Some quick example text to build on the card title and make up the bulk of the card's content. "
                    )
                  ]),
                  _c("b-link", { staticClass: "card-link" }, [
                    _vm._v(" Card link ")
                  ]),
                  _c("b-link", { staticClass: "card-link" }, [
                    _vm._v(" Another link ")
                  ])
                ],
                1
              ),
              _c("h6", { staticClass: "my-2 text-muted" }, [
                _vm._v(" List groups ")
              ]),
              _c(
                "b-card",
                { staticClass: "mb-4", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-list-group",
                    { attrs: { flush: "" } },
                    _vm._l(_vm.listData, function(data) {
                      return _c("b-list-group-item", { key: data.text }, [
                        _vm._v(" " + _vm._s(data.text) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("h6", { staticClass: "my-2 text-muted" }, [
                _vm._v(" Images ")
              ]),
              _c(
                "b-card",
                {
                  attrs: {
                    "img-src": require("@/assets/images/slider/01.jpg"),
                    "img-alt": "Card image",
                    "img-top": ""
                  }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " Some quick example text to build on the card title and make up the bulk of the card's content. "
                    )
                  ]),
                  _c("b-card-text", [
                    _vm._v(
                      " Cookie topping caramels jujubes gingerbread. Lollipop apple pie cupcake candy canes cookie ice cream. Wafer chocolate bar carrot cake jelly-o. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("h6", { staticClass: "my-2 text-muted" }, [
                _vm._v(" Kitchen Sink ")
              ]),
              _c(
                "b-card",
                {
                  attrs: {
                    "img-src": require("@/assets/images/slider/02.jpg"),
                    "img-alt": "Card image",
                    "img-top": "",
                    "no-body": ""
                  }
                },
                [
                  _c(
                    "b-card-body",
                    [
                      _c("b-card-title", [_vm._v("Card title")]),
                      _c("b-card-text", [
                        _vm._v(
                          " Some quick example text to build on the card title. "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-list-group",
                    { attrs: { flush: "" } },
                    _vm._l(_vm.kitchenSinkList, function(data) {
                      return _c("b-list-group-item", { key: data.text }, [
                        _vm._v(" " + _vm._s(data.text) + " ")
                      ])
                    }),
                    1
                  ),
                  _c(
                    "b-card-body",
                    [
                      _c("b-link", { staticClass: "card-link" }, [
                        _vm._v(" Card link ")
                      ]),
                      _c("b-link", { staticClass: "card-link" }, [
                        _vm._v(" Another link ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
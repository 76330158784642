var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "card-text-alignment" } },
    [
      _c("h5", { staticClass: "mt-3 mb-2" }, [_vm._v(" Text Alignment ")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-3",
                  attrs: { title: "Special title treatment" }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " With supporting text below as a natural lead-in to additional content. "
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(113, 102, 240, 0.15)",
                          expression: "'rgba(113, 102, 240, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { variant: "outline-primary" }
                    },
                    [_vm._v(" Go somewhere ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "text-center",
                  attrs: { title: "Special title treatment" }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " With supporting text below as a natural lead-in to additional content "
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(113, 102, 240, 0.15)",
                          expression: "'rgba(113, 102, 240, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { variant: "outline-primary" }
                    },
                    [_vm._v(" Go somewhere ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "text-right",
                  attrs: { title: "Special title treatment" }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " With supporting text below as a natural lead-in to additional content. "
                    )
                  ]),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "ripple",
                          rawName: "v-ripple.400",
                          value: "rgba(113, 102, 240, 0.15)",
                          expression: "'rgba(113, 102, 240, 0.15)'",
                          modifiers: { "400": true }
                        }
                      ],
                      attrs: { variant: "outline-primary" }
                    },
                    [_vm._v(" Go somewhere ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("card-title"),
      _c("card-content-types"),
      _c("card-header-footer"),
      _c("card-text-alignment"),
      _c("card-navigation"),
      _c("card-images"),
      _c("card-style-variation"),
      _c("h5", { staticClass: "mt-3 mb-0" }, [_vm._v(" Card Layout ")]),
      _c("card-basic-group"),
      _c("card-basic-decks"),
      _c("card-basic-columns")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "card-style-variation" } },
    [
      _c("h5", { staticClass: "mt-3 mb-2" }, [_vm._v(" Style Variation ")]),
      _c(
        "b-row",
        _vm._l(_vm.solidColor, function(data, index) {
          return _c(
            "b-col",
            { key: index, attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "bg-variant": data.bg, "text-variant": "white" } },
                [
                  _c("b-card-title", { staticClass: "text-white" }, [
                    _vm._v(" " + _vm._s(data.title) + " ")
                  ]),
                  _c("b-card-text", [
                    _vm._v(
                      " Some quick example text to build on the card title and make up. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "b-row",
        _vm._l(_vm.solidColor, function(data, index) {
          return _c(
            "b-col",
            { key: index, attrs: { md: "6", xl: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "shadow-none",
                  attrs: {
                    "border-variant": data.bg,
                    title: data.title,
                    "bg-variant": "transparent"
                  }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " Some quick example text to build on the card title and make up. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("h6", { staticClass: "my-3 text-muted" }, [_vm._v(" Card Columns ")])
      ]),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c(
            "b-card-group",
            { attrs: { columns: "" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "position-static",
                  attrs: {
                    "img-src": require("@/assets/images/slider/06.jpg"),
                    "img-top": "",
                    "img-alt": "card img",
                    title: "Card title that wraps to a new line"
                  }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. "
                    )
                  ])
                ],
                1
              ),
              _c("b-card", { staticClass: "position-static" }, [
                _c("blockquote", { staticClass: "blockquote mb-0" }, [
                  _c("p", [
                    _vm._v(
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                    )
                  ]),
                  _c("footer", { staticClass: "blockquote-footer" }, [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(" Someone famous in "),
                      _c("cite", { attrs: { title: "Source Title" } }, [
                        _vm._v("Source Title")
                      ])
                    ])
                  ])
                ])
              ]),
              _c(
                "b-card",
                {
                  staticClass: "position-static",
                  attrs: {
                    "img-src": require("@/assets/images/slider/01.jpg"),
                    "img-alt": "card img",
                    "img-top": "",
                    title: "Card title"
                  }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      "This card has supporting text below as a natural lead-in to additional content."
                    )
                  ]),
                  _c("b-card-text", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Last updated 3 mins ago")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-card",
                {
                  staticClass: "position-static",
                  attrs: {
                    "text-variant": "white",
                    "bg-variant": "primary",
                    align: "center"
                  }
                },
                [
                  _c("blockquote", { staticClass: "blockquote  mb-0" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat."
                      )
                    ]),
                    _c(
                      "footer",
                      {
                        staticClass: "blockquote-footer text-white opacity-75"
                      },
                      [
                        _c("small", [
                          _vm._v(" Someone famous in "),
                          _c("cite", { attrs: { title: "Source Title" } }, [
                            _vm._v("Source Title")
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "b-card",
                {
                  staticClass: "position-static",
                  attrs: { align: "center", title: "Card title" }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " This card has supporting text below as a natural lead-in to additional content. "
                    )
                  ]),
                  _c("b-card-text", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Last updated 3 mins ago")
                    ])
                  ])
                ],
                1
              ),
              _c("b-card", {
                staticClass: "position-static",
                attrs: {
                  overlay: "",
                  "img-src": require("@/assets/images/slider/02.jpg"),
                  "img-alt": "overlay img"
                }
              }),
              _c(
                "b-card",
                { staticClass: "position-static", attrs: { align: "right" } },
                [
                  _c("blockquote", { staticClass: "blockquote mb-0" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                      )
                    ]),
                    _c("footer", { staticClass: "blockquote-footer" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(" Someone famous in "),
                        _c("cite", { attrs: { title: "Source Title" } }, [
                          _vm._v("Source Title")
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _c(
                "b-card",
                {
                  staticClass: "position-static",
                  attrs: { title: "Card title" }
                },
                [
                  _c("b-card-text", [
                    _vm._v(
                      " This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action. "
                    )
                  ]),
                  _c("b-card-text", [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("Last updated 3 mins ago")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }